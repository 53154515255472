import { Height } from '@mui/icons-material'
import React from 'react'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export const Qrlist = () => {

  return (
    <div>
        <div className='shadow border mt-5 ms-5 me-5 mb-3 d-flex justify-content-between'>
            <div className=' d-flex'>
                <div>
                    <img className='w-100' style={{width: 100, height: 100}} src={require("../src/asset/qrimg.png")} alt="QR img" />   
                </div>
                <div>
                    <div className='fs-5  text-start'>Website</div>
                    <div className='fs-4 fw-semibold text-start'>Developers141 </div>
                    <div className='fw-light text-start'>created:29-2-2024</div>
                </div>
            </div>
            <div className=' d-flex align-middle'>
                <div className='fs-4 text-start fw-semibold align-middle m-4'>Status: Active</div>
                <div className='fs-5 text-start fw-medium align-middle m-4 p-1'>Total Scan: 12</div>
                <div className='align-middle m-4'>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained">Edit</Button>
                        <Button variant="contained">Download</Button>
                    </Stack>
                </div>
            </div>
        </div>
        <div className='shadow border mt-2 ms-5 me-5 mb-3 d-flex justify-content-between'>
            <div className=' d-flex'>
                <div>
                    <img className='w-100' style={{width: 100, height: 100}} src={require("../src/asset/qrimg.png")} alt="QR img" />   
                </div>
                <div>
                    <div className='fs-5 text-start'>Youtube</div>
                    <div className='fs-4 fw-semibold text-start'>QR in React </div>
                    <div className='fw-light text-start'>created:29-2-2024</div>
                </div>
            </div>
            <div className=' d-flex align-middle'>
                <div className='fs-4 text-start fw-semibold align-middle m-4'>Status: Active</div>
                <div className='fs-5 text-start fw-medium align-middle m-4 p-1'>Total Scan: 102</div>
                <div className='align-middle m-4'>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained">Edit</Button>
                        <Button variant="contained">Download</Button>
                    </Stack>
                </div>
            </div>
        </div>
        <div className='shadow border mt-2 ms-5 me-5 mb-3 d-flex justify-content-between'>
            <div className=' d-flex'>
                <div>
                    <img className='w-100' style={{width: 100, height: 100}} src={require("../src/asset/qrimg.png")} alt="QR img" />   
                </div>
                <div>
                    <div className='fs-5 text-start'>Youtube</div>
                    <div className='fs-4 fw-semibold text-start'>Typography introduction </div>
                    <div className='fw-light text-start'>created:09-2-2024</div>
                </div>
            </div>
            <div className=' d-flex align-middle'>
                <div className='fs-4 text-start fw-semibold align-middle m-4'>Status: Inactive</div>
                <div className='fs-5 text-start fw-medium align-middle m-4 p-1'>Total Scan: 22</div>
                <div className='align-middle m-4'>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained">Edit</Button>
                        <Button variant="contained">Download</Button>
                    </Stack>
                </div>
            </div>
        </div>
    </div>
  )
}
