import axios from 'axios'
import React, { useState } from 'react'
import { Link,  useNavigate } from 'react-router-dom'

export const Site = () => {
    const [loginf, useLoginf] = useState(true)
        
    const navigate= useNavigate()
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    async function loginfunc(e){
        e.preventDefault();
        axios.post('http://localhost:5000/login',{email,password}).then((res)=>{
            if(res.data=='exist'){
                loginf(false);
                navigate('/')
            }
            else{
                alert('check id pass')
            }
        })
    }
  return (
    <div>
        <div className='header'>
            <div className='d-flex justify-content-between sitenavcolor' >
                <div>
                    <img  className='navimg ms-5 sitelogo ' src={require("../src/asset/logo1.png")}   alt="logo" />
                 </div>
                <div className='sitenav center me-5'>
                    <a href='#'>Home</a>
                    <a href='#about'>about</a>
                    <a href='#contact'>Contact</a>
                    {loginf?<a href='#login'>login</a>:null}
                    {loginf?<Link to="/signup" className='gbtn'>Signup</Link>:null}
                    {loginf?null:<Link to="/" className='gbtn'>Generate QR</Link>}
                </div>
            </div>
        </div>
        <div className='herosection mb-5'>
            <div className='herotext'>
                <div className='tblue'>
                <h1 className='herohead tblue mb-4'>Generate QR Codes For Every Thing</h1>
                <span className='fs-4 fw-medium '>WHAT IS QR CODE: </span>
                <span className='fs-5 fw-medium'>A Quick Response code(QR code) is a two dimentional barcode that can store a variety of data, such as website links, Contact information, and more.</span>
                </div>
                <div className='mt-5'>
                <button className='  fw-bold fs-5  btnblue sitenav' >
                    {loginf?<a href='#login'>Login To Generate QR</a>:null}
                    {loginf?null:<Link to="/" className='gbtn'>Generate QR</Link>}
                    </button>
                </div>
            </div>
            <div className='d-flex justify-content-end p-5 '><img  src={require("../src/asset/logo.png")}   alt="logo" /></div>
        </div>
        <section className='logsection d-flex center  ' id='login'>
            {/* <div className=''><img className='w-50 p-5' src={require("./asset/Designer (2).jpeg")}></img></div> */}
           { loginf?(
            <div className='loginsec p-5 '>
                <div className='p-5'>
                    <div className='logbtns'>
                        <button className='loginbtn' >login</button>
                        <button className='signbtn' ><Link to="/signup">Signup</Link></button>
                    </div>
                    <span className='fs-4 fw-medium m-auto text-light tshadow'>Welcome to our community</span>
                    <form className='logform'>

                        <div>
                            <input type='email'placeholder='Email Id' className='loginput' onClick={(event)=>setEmail(event.target.value)}/>
                        </div>
                        <div>
                            <input type='password'placeholder='Password'  className='loginput'onClick={(event)=>setPassword(event.target.value)} />
                        </div>
                        <div className='d-flex justify-content-center m-4'>
                            <Link to="/signup" className='text-light tshadow'>Create an account</Link>
                        </div>
                        <div  className='d-flex justify-content-center'>
                            <input type='submit' className='logbtn' onClick={loginfunc}></input>
                        </div>
                    </form>
                </div>
            </div>):
           
                null
            } 
            
        </section>
        <section id='about'>
        <div className='w90 d-flex pt-5 justify-content-between '>
            <div className='col-6 '>
                <img className='w-100 p-3 me-5 ' src={require("./asset/Designer (8).jpeg")}/>
            </div>
            <div className='ps-5 col-6 pt-5 pb-5 '>
                <div className='mb-4'>
                <span className='fs-3 fw-bold '>BENEFITS OF USING QR CODES</span>
                <dl className='ps-5'>
                    <dt className='fs-3  '>Contactless interaction</dt>
                    <dd className='fs-5'>Facilitates touchless information exchange for increased safety and convenience.</dd>
                    <dt className='fs-3  '>Versatility</dt>
                    <dd className='fs-5'>Can be used for marketing, ticketing, payments, and varius other applications.</dd>
                    <dt className='fs-3  '>Enhanced tracking</dt>
                    <dd className='fs-5'>Provides valuable analytics and insights into consumer behaviors and preferences.</dd>
                </dl>
            </div>
            <div>
                <span className='fs-3 fw-bold'>HOW TO GENERATE A QR CODE</span>
                <dl className='ps-5'>
                    <dt className='fs-3  '>Select Generate QR</dt>
                    <dd className='fs-5'>Use an online tool to create a QR code based on your content.</dd>
                    <dt className='fs-3  '>Enter content</dt>
                    <dd className='fs-5'>Enter the data to be encoded, such as a URL, text or information.</dd>
                    <dt className='fs-3  '>Download and share</dt>
                    <dd className='fs-5'>Save the generated QR code image and share everywhere to promote</dd>
                </dl>
            </div>
           
            </div>
        </div>
        </section >
        <section className='d-flex justify-content-center p-5  mar ' id='contact'>
                <div className=' p-5 border bimg rounded'></div>
                  <div className=' contactpad'>
                  <span className='d-flex justify-content-center fs-3 fw-bold text-light m-5'>We are here to help you</span>
                  <div>
                      <div className='d-flex justify-content-center text-light gap-3 p-3'>
                         <span className='textw'>Enter your name</span>
                         <input type='text' className='boxline inputw' ></input>
                    </div>
                    <div className='d-flex justify-content-center text-light gap-3 p-3'>
                        <span className='textw'>Enter your email</span>
                        <input type='email'className='boxline inputw'></input>
                    </div>
                    <div className='d-flex justify-content-center text-light gap-3 p-3'>
                        <span className='textw'>Enter your message</span>
                        <textarea className='boxline inputarea' rows={5} cols={20}></textarea>
                    </div>
                        <div className='d-flex justify-content-center  '>
                        <button className='contactbtn' >SUBMIT</button>
                    </div>
                </div>
            </div>
                
        </section>
        
        <section className='mar1 footbg'>
            <div className='d-flex justify-content-center '>
                <img className='navimg ' src={require("./asset/logo1.png")} />
            </div>
            <div className='sitenav center me-5'>
                    <a href='#'>Home</a>
                    <a href='#about'>about</a>
                    <a href='#contact'>Contact</a>
                    {loginf?<a href='#login'>login</a>:null}
                    {loginf?<Link to="/signup" className='gbtn'>Signup</Link>:null}
                    {loginf?null:<Link to="/" className='gbtn'>Generate QR</Link>}
                </div>
                <div>
                <span className='center text-light pb-3'>Copyright @ happy works |  All rights reserved</span>
                </div>
        </section>
    </div>
  )
}
