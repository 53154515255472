import React from 'react'


import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Qrlist } from './Qrlist';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [

  'Active',
  'in active',
  'payment qr',
  'address qr',
  'mail id qr',
  'insta id qr',

];


export const Myqr = () => {
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const [sortName, setsortName] = React.useState([]);

    const sortChange = (event) => {
      const {
        target: { value },
      } = event;
      setsortName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
  return (
    <div >
        <div className=' m-5 text-start fs-2 fw-bold'>
            <p>MY QR CODES</p>
        </div>
        <div className='d-flex justify-content-between'>
            <div className='ms-5 '>
                <Stack spacing={2} direction="row">
                    <Button className='ps-5 pe-5 pt-3 pb-3 fw-medium fs-5 rounded-2'  variant="contained">Create QR Codes</Button>
                </Stack>
            </div>
            <div className='d-flex  me-5'>
            <div className='ms-5 justify-content-end'>
                <FormControl sx={{ m: 1, width: 200 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Filter</InputLabel>
                    <Select labelId="demo-multiple-checkbox-label" id="demo-multiple-checkbox"  multiple value={personName} onChange={handleChange} 
                    input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}>
                        {names.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <FormControl sx={{ m: 1, width: 200 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Sort</InputLabel>
                    <Select labelId="demo-multiple-checkbox-label" id="demo-multiple-checkbox"  multiple value={sortName} onChange={sortChange} 
                    input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}>
                        {names.map((name) => (
                            <MenuItem key={name} value={name}>
                            <Checkbox checked={sortName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '25ch' }, }} noValidate autoComplete="off" >
                <TextField id="outlined-basic" label="search" variant="outlined" />
                </Box>
            </div>
            </div>
        </div>
        <div>
           <Qrlist></Qrlist></div>
    </div>
  )
}
