import './App.css';
import { Dashmenu } from './Dashmenu';
import { Dashcontent } from './Dashcontent';
import { Myqr } from './Myqr';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Analist } from './Analist';

import { Profile } from './Profile';
import { Site } from './Site';
import { Sign } from './Sign';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route  element={<Dashmenu></Dashmenu>}>
            <Route path='/' element={<Dashcontent></Dashcontent>}></Route>
            <Route path='/Qr_list' element={<Myqr></Myqr>}></Route>
            <Route path='/analise_qr' element={<Analist></Analist>}></Route>
            <Route path='/profile' element={<Profile></Profile>}></Route>
          </Route>
          <Route path='/site' element={<Site></Site>}></Route>
          <Route path='/signup' element={<Sign></Sign>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
