import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import QRCode from 'qrcode.react';
import { QrCode, Style } from '@mui/icons-material';
import styled from 'styled-components';

export const Dashcontent = () => {

  const [text1, setText1] = useState('');
  const [text, setText] = useState('');
  const [fgColor, setFgColor] = useState('#000000'); // Default foreground color is black
  const [bgColor, setBgColor] = useState('#ffffff'); // Default background color is white


  const handleChange1 = (event) => {
    setText1(event.target.value);
  };
  const handleChange = (event) => {
    setText(event.target.value);
  };
  const handleFgColorChange = (event) => {
    setFgColor(event.target.value);
  };
  const handleBgColorChange = (event) => {
    setBgColor(event.target.value);
  };
  const handleDownload = () => {
    const canvas = document.getElementById('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.download = 'happyworks_'+text1+'_qrcode.png'; // Change the file name as needed
    link.href = url;
    link.click();
  };
  return (
    <div className='dashcontent'>
      
      <div className=' mt-5 ms-5  text-start fs-2 fw-bold'>
        <p>Create QR Codes</p>
      </div>
      <div className='d-flex mb-5 ms-5 mt-4 me-5'>
        <div className='col shadow border me-5 p-3 '>
          <div className='center'>
          <div className='w-100'>
            <Box component="form" sx={{ '& .MuiTextField-root': { mt: 2, mb: 1, mr: 0,ml:1, width: '100%' }, }} noValidate autoComplete="off">
              <div >
                <div>
                  <TextField id="outlined-required" label="Name Of Your QR Code" type="text" value={text1} onChange={handleChange1} />
                </div>
                <div>
                  <TextField id="outlined-required" label="www.google.com"  type="text" value={text} onChange={handleChange} />
                </div>
                <div>
                  <TextField id="outlined-required" label="Type Of QR Code" defaultValue="Website link"/>
                </div>
              </div>
            </Box>
          </div>
          </div>
        </div>
        <div className='col shadow border ms-5 p-3 '>
        <div className=' center' id='code canvas'>
            {text ? null : <img className='qrimg' src={require("../src/asset/qrimg.png")}  alt="QR img" />}
            {text && <QRCode className='code' id='canvas' value={text} fgColor={fgColor} bgColor={bgColor} size={200} />} 
          </div>

          <h4 className='center'>Dynamic Code</h4>
        </div>
      </div>
      <div className='d-flex'>
        <div className='col shadow border ms-5 me-5  p-3 w-100'>
          <Box component="form" sx={{'& .MuiTextField-root': { mt: 1, mb: 1, mx:1, width: '100%' },}} noValidate autoComplete="off">
            <div >
              <div>
                <TextField required id="outlined-required fg-color-input" label="foreColor" type="color" value={fgColor}
          onChange={handleFgColorChange} defaultValue="blue"/>
              </div>
              <div>
                <TextField id="outlined-required bg-color-input"  type="color" value={bgColor} onChange={handleBgColorChange} label="Background Color" defaultValue="none"/>
              </div>
            </div>
          </Box>
        </div>
        <div className='col shadow border ms-5 me-5 p-3 w-100'>
          <div>
            <p className='text-start ms-2 fs-5 fw-medium'>Download QR Code (PNG file)</p>
            
            <div className='ms-2 me-2'>
              <Stack spacing={2} direction="row" >
                <Button variant="contained" className='mt-2 p-3 w-100 'onClick={handleDownload}>DOWNLOAD</Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </div>  
  )
}
