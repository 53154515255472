import React from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export const Analist = () => {
  return (
    <div>
        <div className=' ms-5 mt-5 text-start fs-2 fw-bold'>
            <p className='m-0'>QR ANALYSTICS</p>
        </div>
        <div className='d-flex justify-content-between  ms-5 me-5'>
            <div className='d-flex '>
                <div>
                    <img className='w-100' style={{width: 200, height: 200}} src={require("../src/asset/qrimg.png")} alt="QR img" />
                </div>
                <div>
                    <p className='fs-2 fw-medium pt-5 ps-2' >Developers141</p>
                </div>
            </div>
            <div>
            <div className=' d-flex align-middle mt-4 '>
                <div className='fs-4 text-start m-4'> Active</div>
                <div className='fs-4 text-start m-4'>created:29-2-2024</div>
                <div className='fs-4 text-start m-4'>Total Scan: 12</div>
            </div>
            </div>
        </div>
        <div className='d-flex ms-5 me-5 justify-content-between'>
            <div className=''>
                <div>
                    <p className='text-start fw-medium fs-4'>Edit website url</p>
                </div>
                <div className='text-start'>
                    <Box component="form" sx={{'& > :not(style)': {width: '50ch' }, }} noValidate autoComplete="off">
                        <TextField id="outlined-basic" label="www.developers141.com" variant="outlined" />
                    </Box>
                </div>
            </div>
            <div className='  align-items-end gap-4 d-flex'>
                <Button variant="contained" className='  ps-5 pe-5 pt-3 pb-3'>Edit & Save</Button>
                <Button variant="contained" className=' ps-5 pe-5 pt-3 pb-3'>Download</Button>
            </div>
        </div>
        <div>
            <p className='fw-medium fs-4 text-start ms-5 mt-5 '>Scanned Activity</p>
        </div>
        <div className='d-flex ms-5 me-5 mt-3 mb-2 gap-5 align-items-center shadow border'>
            <div>
                <img className='w-100' style={{width: 60, height: 60}} src={require("../src/asset/qrimg.png")} alt="QR img" /> 
            </div>
            <div>
            <p className=' fs-5 text-start '>City: chennai</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Date: 29-02-2024</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Time: 06:30:24:pm</p>
            </div>
            <div>
            <p className='fs-5 text-start '>IP: 192.0.2.1</p>
            </div>
        </div>
        <div className='d-flex ms-5 me-5 mt-3 mb-2 gap-5 align-items-center shadow border'>
            <div>
                <img className='w-100' style={{width: 60, height: 60}} src={require("../src/asset/qrimg.png")} alt="QR img" /> 
            </div>
            <div>
            <p className=' fs-5 text-start '>City: chennai</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Date: 29-02-2024</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Time: 06:30:24:pm</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>IP: 192.0.2.1</p>
            </div>
        </div>
        <div className='d-flex ms-5 me-5 mt-3 mb-2 gap-5 align-items-center shadow border'>
            <div>
                <img className='w-100' style={{width: 60, height: 60}} src={require("../src/asset/qrimg.png")} alt="QR img" /> 
            </div>
            <div>
            <p className=' fs-5 text-start '>City: chennai</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Date: 29-02-2024</p>
            </div>
            <div>
            <p className=' fs-5 text-start '>Time: 06:30:24:pm</p>
            </div>
            <div>
            <p className='fs-5 text-start '>IP: 192.0.2.1</p>
            </div>
        </div>

    </div>
  )
}
