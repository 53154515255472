import axios from 'axios'
import React, { useState } from 'react'
import { Link,  useNavigate } from 'react-router-dom'

export const Sign = () => {
    const navigate= useNavigate()
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    async function signupfunc(e){
        e.preventDefault();
        axios.post('http://localhost:5000/signup',{name,email,password}).then((res)=>{
            if(res.data=='exist'){
                alert('Your email is already registered')
            }
            else{
                navigate('/site')
            }
        })
    }
  return (
    <div>
        <div className='header'>
            <div className='d-flex justify-content-between sitenavcolor' >
                <div>
                    <img  className='navimg ms-5 sitelogo ' src={require("../src/asset/logo1.png")}   alt="logo" />
                 </div>
                <div className='sitenav center me-5'>
                    <a href='#'>Home</a>
                    <a href='#signup'>Signup</a>
                    <Link to="/site" className='gbtn'>Login</Link>
                </div>
            </div>
        </div>
        <div className='herosection mb-5'>
            <div className='herotext'>
                <div className='tblue'>
                <h1 className='herohead tblue mb-4'>Generate QR Codes For Every Thing</h1>
                <span className='fs-4 fw-medium '>WHAT IS QR CODE: </span>
                <span className='fs-5 fw-medium'>A Quick Response code(QR code) is a two dimentional barcode that can store a variety of data, such as website links, Contact information, and more.</span>
                </div>
                <div className='mt-5'>
                <button className='  fw-bold fs-5  btnblue sitenav' ><a href='#signup'>Signup</a> </button>
                </div>
            </div>
            <div className='d-flex justify-content-end p-5 '><img  src={require("../src/asset/logo.png")}   alt="logo" /></div>
        </div>
        <section className='logsection d-flex center  ' id='signup'>
        <div className='loginsec p-5' >
            <div className='p-5'>
                <div className='logbtns'>
                    <button className='loginbtn' >signup</button>
                    <button className='signbtn' ><Link to="/site">login</Link></button>
                </div>
                    <span className='fs-4 fw-medium m-auto'>Welcome to our community</span>
                    <form className='logform'>
                    <div>
                        <input type='text'placeholder='Name' onChange={(event)=>setName(event.target.value)} className='loginput'/>
                    </div>
                    <div>
                        <input type='email'placeholder='Email Id' onChange={(event)=>setEmail(event.target.value)} className='loginput'/>
                    </div>
                    <div>
                        <input type='password'placeholder='Password' onChange={(event)=>setPassword(event.target.value)} className='loginput'/>
                    </div>
                    <div className='d-flex justify-content-center m-4'>
                        <Link to="/site">already have an account</Link>
                    </div>
                    <div  className='d-flex justify-content-center'>
                        <input className='logbtn ' onClick={signupfunc} type="submit" />
                    </div>
                </form>
            </div>
        </div>
        </section>
        <section className='mar1 footbg'>
            <div className='d-flex justify-content-center '>
                <img className='navimg ' src={require("./asset/logo1.png")} />
            </div>
            <div className='sitenav center me-5'>
                <a href='#'>Home</a>
                <a href='#about'>about</a>
                <a href='#contact'>Contact</a>
                <a href='#login'>login</a>
                <Link to="/signup" className='gbtn'>Signup</Link>
                <Link to="/" className='gbtn'>Generate QR</Link>
            </div>
            <div>
                <span className='center text-light pb-3'>Copyright @ happy works |  All rights reserved</span>
            </div>
        </section>
    </div>
  )
}

