import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const Dashmenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const profile = () => {
    window.location.href = "/site";
  };
  const logout = () => {
    window.location.href = "/site";
  };
  return (
    
    <div className='navcontain'>
      <div className='container1' >
        <div className='dashmenu'>
          <Link  to="/site"><img  className='navimg  ' src={require("../src/asset/logo1.png")}   alt="logo" /></Link>
          <div className='d-flex'>
            <div className='link right '>
              <Link className=' fs-4 fw-medium link-underline link-underline-opacity-0 me-5 text-light' to="/">Generate</Link>
              <Link className=' fs-4 fw-medium link-underline link-underline-opacity-0 me-5 text-light' to="/Qr_list">My Qr</Link>
              <Link className=' fs-4 fw-medium link-underline link-underline-opacity-0  text-light' to="/analise_qr">Analist</Link>
            </div>
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
              <img  className='navimg img-light ms-4 rounded-circle p-3' src={require("../src/asset/77ee52fecf454d92aa733afca5e3ff99.webp")}   alt="logo" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              {/* <MenuItem onClick={profile}>Profile</MenuItem> */}
              <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <Outlet></Outlet>
      </div>
    </div>
  )
}
